import 'jquery';
import 'popper.js';
import 'bootstrap';

import {loading} from "./loading";
import {dropdown} from "./dropdown";
import {bootstrapInit} from "./bootstrap-init";
import {deferImages} from './image-deferer';

jQuery(document).ready(() => {
    loading("stop", 1);
    dropdown();
    bootstrapInit();
    deferImages();
});

jQuery(document).on('scroll', () => {
    deferImages();
});