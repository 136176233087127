export function dropdown() {

    jQuery('.dropdown').hover(
        function() {
            jQuery(this).addClass("hover");
        }, function() {
            jQuery(this).removeClass("hover");
        }
    );

}