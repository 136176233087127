export function loading(type, time) {

    let LoadingElem = jQuery('#mainloading');

    window.onbeforeunload = function (e) {
        loading('start');
    };

    let delay = time || 0;

    switch(type) {
        case "stop" :
            setTimeout(function() {
                LoadingElem.animate({'opacity': 0}, 500, function(){
                    jQuery(this).hide();
                });
            }, delay);
            break;

        case "start" :
            setTimeout(function() {
                LoadingElem.show();
                LoadingElem.animate({'opacity': 1}, 300);
            }, delay);
    }

}