import 'jquery.appear';

const defaultPictureMan = "//static3.machteamsoft.ro/thumbnails_med/man_default2.gif";
const defaultPictureWoman = "//static3.machteamsoft.ro/thumbnails_med/woman_default2.gif";

export function deferImages() {
    jQuery('.delayed-image').appear(function() {
        const imageSrc = jQuery(this).attr('src');
        const imageDataSrc = jQuery(this).attr('data-src');
        imageSrc !== imageDataSrc && imageSrc !== defaultPictureMan && imageSrc !== defaultPictureWoman ?
            jQuery(this).attr('src', imageDataSrc) :
            null;
    });
}